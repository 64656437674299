globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"1bc2da105829defcae47a2c5d6a2f59db2287d3a"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';

Sentry.init({
  dsn: 'https://490613dcab92107eb636ffff0121af01@o866780.ingest.sentry.io/4506398899306496',
  ...standardSentryConfig,
  ...extraClientConfig,
});
